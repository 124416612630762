@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --toastify-color-progress-light: #8fc43c !important;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}